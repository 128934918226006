import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import Collection from '../../../components/collection'
import WistiaVideo from '../../../components/wistia-video'
import SourceEmitter from '../../../lib/emitter'
import VideoCollection from '../../../lib/videos/consumer-videos'

let videoPlayerSubscription = null

const metaTags = {
  description:
    'Watch videos for caregivers of patients treated with SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Video Library',
}
class ResourcesVideoPage extends React.Component {
  constructor() {
    super()
    this.state = {
      currentVideo: VideoCollection[1],
    }
  }

  componentDidMount() {
    // From collection item
    this.setResponseParams()
    videoPlayerSubscription = SourceEmitter.addListener(
      `onPatientVideoClick`,
      (video) => {
        this.setState({
          currentVideo: video,
        })
      }
    )
  }

  componentWillUnmount() {
    videoPlayerSubscription && videoPlayerSubscription.remove()
  }

  setResponseParams() {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map((el) => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1]
        return pre
      }, {})
    const vidId = responseObj.video
    if (responseObj.video) {
      window.scrollTo(0,0)
      this.setState({
        currentVideo: VideoCollection[vidId],
      })
    }
  }

  render() {
    const { currentVideo } = this.state

    return (
      <Layout meta={metaTags}>
        <Row>
          <Col xs={12}>
            <h1>
              SUPPRELIN<sup>&reg;</sup> LA Connection Video Library
            </h1>
          </Col>
        </Row>
        <Row center={'sm'}>
          <Col md={8} lg={10}>
            <WistiaVideo wistiaID={currentVideo.wistiaID} />
          </Col>
        </Row>
        <Row center={'md'}>
          <Col xs={12}>
            <Collection
              centered
              video
              collection={VideoCollection}
              pageName="patient caregiver resources"
            />
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default ResourcesVideoPage;
